<template>
  <div class="rf-input-translations" :style="inputTranslationsStyle">
    <div class="d-flex align-items-center">
      <div class="input-translations">
        <RFInput :label="label" :color="color" @key-enter="saveValue" v-model="value" v-if="languages && languages.length" :readonly="readonly"/>
        <FontAwesomeIcon class="add-value" :icon="['fas', 'check-circle']" color="#5CB85C" @click="saveValue" v-if="value && languages && languages.length"/>
      </div>
      <div class="languages" v-if="languages && languages.length">
        <div class="selected-language" @click="isOpened = !isOpened">
          <flag :squared="false" :iso="selectedLanguage"/>
          <FontAwesomeIcon class="ml-1" :icon="['fas', 'caret-down']" />
        </div>
        <div class="choose-languages" v-if="isOpened">
          <div class="choose-language" v-for="(language, index) in languages" :key="index" @click="selectLanguage(language)">
            <flag class="language" :squared="false" :iso="language"/>
          </div>
        </div>
      </div>
    </div>
    <div class="values" :class="{ 'my-4': languages && !languages.length }" v-if="values && values.length">
      <div class="translations-label" v-if="languages && !languages.length">{{label}}</div>
      <div class="value" v-for="(value, index) in values" :key="index">
        <flag class="language" :squared="false" :iso="value.language"/>
        <div class="translation-value mx-2" @click="selectValue(value.value)">{{value.value | truncate(0, 30, true)}}</div>
        <FontAwesomeIcon class="cursor-pointer" :icon="['fas', 'times']" @click="!readonly ? removeValue(value) : null"/>
      </div>
    </div>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'

export default {
  name: 'RFInputTranslations',
  components: {
    RFInput,
  },
  props: {
    label: String,
    color: {
      type: String,
      default: '#24425B',
    },
    prefilledTranslations: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      value: null,

      translations: {
        en: null,
        it: null,
        es: null,
      },
      languages: ['it', 'us', 'es'],
      selectedLanguage: 'us',
      isOpened: false,
      values: [],
    }
  },
  computed: {
    inputTranslationsStyle() {
      return {
        '--color': this.color,
      }
    }
  },
  created() {
    if (this.prefilledTranslations) {
      this.translations = this.prefilledTranslations

      Object.keys(this.translations).forEach(key => {
        if (this.translations[key]) {
          this.values.push({ language: key === 'en' ? 'us' : key, value: this.translations[key] })
          const index = this.languages.findIndex(language => key === 'en' ? language === 'us' : language === key)
          this.languages.splice(index, 1)
        }
      })

      this.selectedLanguage = this.languages[0]
    }
  },
  watch: {
    translations: {
      deep: true,
      handler() {
        this.$emit('change', this.translations)
      }
    }
  },
  methods: {
    selectLanguage(language) {
      this.selectedLanguage = language
      this.isOpened = false
    },
    selectValue(value) {
      this.value = value
    },
    removeValue(removedValue) {
      const index = this.values.findIndex(value => value.language === removedValue.language)
      this.values.splice(index, 1)
      this.languages = [...this.languages, removedValue.language]
      this.languages.reverse()
      this.selectedLanguage = this.languages[0]

      removedValue.language = removedValue.language === 'us' ? 'en' : removedValue.language
      this.translations[removedValue.language] = null
    },
    saveValue() {
      this.isOpened = false

      if (!this.value) {
        return false
      }

      if (this.selectedLanguage === 'us') {
        this.selectedLanguage = 'en'
      }

      this.translations[this.selectedLanguage] = this.value
      this.values = [...this.values, { value: this.value, language: this.selectedLanguage === 'en' ? 'us' : this.selectedLanguage }]
      this.value = null

      this.selectedLanguage = this.selectedLanguage === 'en'
                              ? 'us'
                              : this.selectedLanguage

      const index = this.languages.findIndex(language => language === this.selectedLanguage)
      this.languages.splice(index, 1)
      this.selectedLanguage = this.languages[0]
    },

  }
}
</script>

<style lang="scss" scoped>
.rf-input-translations {
  .values {
    display: flex;
    align-items: center;
    margin-top: 5px;
    flex-wrap: wrap;

    .translations-label {
      @include font-style($montserrat, 'semibold', $font-14);
      margin-right: 20px;
    }
    .value {
      display: flex;
      align-items: center;
      @include font-style($montserrat-light, 'light', $font-10);
      border: 1px solid var(--color);
      border-radius: 10px;
      width: fit-content;
      padding: 1px 5px;
      margin: 5px 10px 0 0;
      cursor: pointer;
      white-space: nowrap;
    }
  }
  .input-translations {
    position: relative;
    width: 100%;

    .add-value {
      position: absolute;
      top: 55%;
      // margin-bottom: 20px;

      right: 10px;
      cursor: pointer;
      z-index: 20;
      // transform: translateY(-50%);
    }
  }
  .languages {
    position: relative;
    margin-left: 10px;
    height: 100%;
    margin-top: 20px;

    .selected-language {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .choose-languages {
      position: absolute;
      border: 1px solid $dark-blue;
      display: flex;
      align-items: center;
      flex-direction: column;
      left: 0;
      margin-top: 10px;
      z-index: 20;

      .choose-language {
        background-color: $white;
        display: block;
        cursor: pointer;
        padding: 3px 10px;

        .language {
          height: 100%;
        }
        &:hover {
          background-color: $dark-blue;
        }
      }
    }
  }
}
</style>